// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-shroom-gatsby-woocommerce-src-pages-404-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/404.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-404-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-cart-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/cart.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-cart-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-checkout-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/checkout.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-checkout-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-contact-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/contact.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-contact-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-order-success-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/order-success.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-order-success-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-pl-dziekujemy-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/pl/dziekujemy.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-pl-dziekujemy-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-pl-kontakt-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/pl/kontakt.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-pl-kontakt-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-pl-koszyk-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/pl/koszyk.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-pl-koszyk-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-pl-lista-zyczen-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/pl/lista-zyczen.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-pl-lista-zyczen-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-pl-subskrypcje-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/pl/subskrypcje.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-pl-subskrypcje-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-pl-zamowienie-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/pl/zamowienie.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-pl-zamowienie-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-pl-zamowienie-przyjete-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/pl/zamowienie-przyjete.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-pl-zamowienie-przyjete-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-shroomscriptions-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/shroomscriptions.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-shroomscriptions-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-thank-you-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/thank-you.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-thank-you-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-pages-wishlist-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/pages/wishlist.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-pages-wishlist-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-archive-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates/archive/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-archive-index-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-front-page-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates/front-page/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-front-page-index-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-my-account-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates/my-account/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-my-account-index-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-page-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates/page/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-page-index-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-pl-archive-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates-pl/archive/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-pl-archive-index-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-pl-front-page-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates-pl/front-page/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-pl-front-page-index-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-pl-my-account-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates-pl/my-account/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-pl-my-account-index-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-pl-page-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates-pl/page/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-pl-page-index-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-pl-product-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates-pl/product/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-pl-product-index-js" */),
  "component---packages-shroom-gatsby-woocommerce-src-templates-product-index-js": () => import("./../../../../packages/shroom-gatsby-woocommerce/src/templates/product/index.js" /* webpackChunkName: "component---packages-shroom-gatsby-woocommerce-src-templates-product-index-js" */)
}

