import { gql } from "@apollo/client";
import { client } from "../apollo/client";
import { logOut } from "./functions";

// GraphQL mutation to refresh JWT token
const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshJwtAuthToken(input: { jwtRefreshToken: $refreshToken }) {
      authToken
    }
  }
`;

// GraphQL query to get new expiration time
const GET_USER_EXPIRATION_QUERY = gql`
  query GetUserExpiration($id: ID!) {
    user(id: $id, idType: DATABASE_ID) {
      jwtAuthExpiration
    }
  }
`;

export const refreshAuthToken = async () => {
  // Retrieve auth data from localStorage
  const authData = JSON.parse(localStorage.getItem("auth"));
  if (!authData) return;

  const { authToken, jwtAuthExpiration, refreshToken, user } = authData;
  const currentTime = Math.floor(Date.now() / 1000);
  //console.log(currentTime < jwtAuthExpiration - 60);
  // Check if token is expired (with a buffer of 120 seconds)
  if (currentTime < jwtAuthExpiration - 120) return;

  console.log("Token needs refresh");

  try {
    // Refresh token mutation
    const { data: refreshData } = await client.mutate({
      mutation: REFRESH_TOKEN_MUTATION,
      variables: { refreshToken },
    });

    //console.log(refreshData);

    const newAuthToken = refreshData?.refreshJwtAuthToken?.authToken;

    //console.log(newAuthToken);

    if (newAuthToken) {
      // Update localStorage with the new authToken
      authData.authToken = newAuthToken;

      // Query to get new expiration
      const { data: expirationData } = await client.query({
        query: GET_USER_EXPIRATION_QUERY,
        variables: { id: user.databaseId },
        fetchPolicy: "network-only", // Ensures fetching the latest data
      });

      const newJwtAuthExpiration = expirationData?.user?.jwtAuthExpiration;

      if (newJwtAuthExpiration) {
        // Update expiration in authData and localStorage
        authData.jwtAuthExpiration = newJwtAuthExpiration;
        localStorage.setItem("auth", JSON.stringify(authData));
      }

      console.log("Token refreshed successfully");
    }
  } catch (error) {
    // for dev purpose
    // console.log("Error refreshing auth token:", error);
    console.log("Logged out...");
    // Handle error (e.g., log out the user if refresh fails)
    logOut();
  }
};

// Use this function in your components or set up an interval to periodically check and refresh the token if needed.
