module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.shroom4you.com/graphql","verbose":true,"schema":{"timeout":3000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":10},"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":true,"hardCacheData":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false},"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"imageQuality":90,"createStaticFiles":true},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":5000}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shroom Shop","short_name":"Shroom Shop","start_url":"/","background_color":"#eaeaea","theme_color":"#1e1e1e","display":"standalone","icons":[{"src":"/favicon-192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/favicon.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../packages/shroom-gatsby-woocommerce/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://admin.shroom4you.com","gatsbySiteUrl":"https://shop.shroom4you.com","googleTagManagerId":"GTM-NV9MRZB","fbAppId":"xxx"},
    }]
